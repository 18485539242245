<template>
    <div>

        <v-layout wrap justify-center>
            <v-flex lg11>



                <v-layout wrap justify-center>
                    <v-flex xs12 sm9 md9 lg9 px-5 pt-10>
                        <span style="font-family: Montserrat-Bold; font-size: 28px;">Refund Policy</span>
                    </v-flex>

                    <v-flex xs12 sm3 md3 lg3 px-5 pt-2 pt-sm-10 class="text-sm-end">
                        <v-btn style="background-color: #00A08E;" @click="editdialog = true">
                            <span style="font-family: Lato-Regular; font-size: 16px; color: white;">Edit</span>
                        </v-btn>
                    </v-flex>

                    <!-- <v-flex xs12 sm12 md12 lg12 px-5 pt-10 class="text-sm-end">
                <v-btn style="background-color: #00A08E;" @click="adddialog = true">
              <span style="font-family: Lato-Regular; font-size: 16px; color: white;">Add</span>
            </v-btn>
            </v-flex> -->

                </v-layout>

                <v-layout wrap justify-center>
                    <v-flex xs12 sm12 md12 lg12 pt-5 px-6>
                        <v-card>
                            <v-layout wrap justify-center>
                                <v-flex xs12 sm12 md12 lg12 pa-4>

                                    <div class="px-5 py-3" v-html="aboutlist.content"></div> <!-- ✅ Display content -->

                                </v-flex>
                            </v-layout>
                        </v-card>
                    </v-flex>
                </v-layout>

                <v-dialog v-model="adddialog" max-width="800px">
                    <v-card rounded="lg">
                        <v-card-title>
                            <span class="editbox">Edit Refund Policy</span>
                            <v-spacer></v-spacer>
                            <v-btn color="red" icon @click="adddialog = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-card-title>

                        <v-card-text>
                            <v-form>
                                <v-layout wrap justify-center px-2>
                                    <v-flex xs12 pr-2 pt-3>
                                        <span class="label"> Description </span>
                                        <vue-editor class="pt-2 text-des" style="font-size: 14px"
                                            v-model="aboutlist.content"></vue-editor>
                                    </v-flex>
                                </v-layout>
                            </v-form>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions class="py-4 justify-end headline grey lighten-2">
                            <v-btn outlined color="grey" text @click="editdialog = false"><span
                                    style="color: black">Cancel</span>
                            </v-btn>

                            <v-btn dark color="#1A73E9" @click="addRefundPolicy()" class="rounded-lg carousaladdedit">
                                <span style="text-transform: none">Save Changes</span>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-dialog v-model="editdialog" max-width="800px">
                    <v-card rounded="lg">
                        <v-card-title>
                            <v-layout wrap justify-center>
                                <v-flex xs10 sm10 md10 lg10>
                                    <span style="
                                        font-family: Montserrat-Regular;
                                        font-size: 24px;
                                        font-weight: 700;
                                    ">Edit Refund Policy</span>
                                </v-flex>

                                <v-flex xs2 sm2 md2 lg2 class="text-end">
                                    <v-btn icon @click="editdialog = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                                </v-flex>
                            </v-layout>
                        </v-card-title>

                        <v-card-text>
                            <v-form>
                                <v-layout wrap justify-center>
                                    <v-flex xs12 pr-2>
                                        <!-- <span class="label"> Description </span> -->
                                        <vue-editor class="pt-2 text-des" style="font-size: 14px"
                                            v-model="aboutlist.content"></vue-editor>
                                    </v-flex>
                                </v-layout>
                            </v-form>
                        </v-card-text>

                        <v-card-actions class="pb-5 pt-0 text-center justify-center">
                            <!-- <v-btn outlined color="grey" text @click="editdialog = false"><span
                                    style="color: black">Cancel</span>
                            </v-btn> -->

                            <!-- <v-btn dark color="#1A73E9" @click="addRefundPolicy()" class="rounded-lg carousaladdedit">
                                <span style="text-transform: none">Save Changes</span>
                            </v-btn> -->

                            <v-btn color="#00A08E" @click="addRefundPolicy()">
                  <span
                    style="
                      font-family: Montserrat-Regular;
                      font-weight: 600;
                      color: white;
                    "
                    >Submit</span
                  >
                </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

            </v-flex>
        </v-layout>
    </div>
</template>
  
  <script>
import axios from "axios";

export default {
  data() {
    return {
      adddialog: false,
      editdialog: false,
      expandOnHover: false,
      aboutlist: {
        content: "", // Ensuring this is reactive
      },
      currentPage: 1,
      userType: null, // Assuming this is needed based on the watcher
    };
  },
  watch: {
    currentPage() {
      this.getData();
    },
    userType() {
      this.getData();
      this.currentPage = 1;
    },
  },
  mounted() {
    this.getPolicy();
  },
  methods: {
    addRefundPolicy() {
    axios({
      method: "POST",
      url: "/policy/add-update",
      headers: {
        token: localStorage.getItem("token"),
      },
      data: {
        type: "Refund Policy",
        content: this.aboutlist.content, // ✅ Corrected to use content
      },
    })
      .then((response) => {
        if (response.data.status) {
          this.getPolicy(); // ✅ Refresh the policy list
          this.adddialog = false; // ✅ Close dialog
          this.editdialog = false;
        } else {
          this.msg = response.data.msg;
          this.showsnackbar = true;
        }
      })
      .catch((err) => {
        this.ServerError = true;
        console.log(err);
      });
  },

  getPolicy() {
    axios({
      url: "/policy/view",
      method: "GET",
      headers: {
        token: localStorage.getItem("token"),
      },
      params: {
        type: "Refund Policy",
      },
    })
      .then((response) => {
        if (response.data.status) {
          this.aboutlist.content = response.data.data.content; // ✅ Set existing content
        }
      })
      .catch((err) => {
        this.ServerError = true;
        console.log(err);
      });
  },
  },
};
</script>

  